.stadi{
    font-size: xx-large;
}
.verse{
    font-size: xx-large;
}
.container{
    text-align: center;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: 'DentonRegular';
}
.hide{
    opacity:0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
}
.active{
    opacity:1;
    visibility:visible;
    transition: all 0.2s ease-in-out;
    transition-delay: 0.2s;
}
h4{
    font-family: 'DentonRegular';
}
.forgotten{
    margin-top: 8px;
}
.forgottentext{
    font-size: 0.7rem;
    color:var(--sub2);
    transition: all 0.1s ease-in-out;
    padding:0.2rem;
    margin:0.8rem;
    cursor: pointer;
}
.forgottentext:hover{
    color:var(--sub1);
    font-size: 0.9rem;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
}
.login_inputs{
    justify-content: center;
}
.form{
    display:inline-table;
    align-items: center;
    justify-content: center;
}
.submitButton{
    margin-top: 12px;
    cursor: pointer;
    border: var(--border);
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 8px;
    width: 200px;
    height:32.5px;
    font-size: 11px;
    background-color: var(--sub1);
    border-radius: 5px;
    font-family: inherit;
}
.textInput{
    margin-top: 12px;
    background-color: var(--bg);
    font-family: inherit;
    font-size: 15px;
    display: flex;
    width: 186px;
    border-radius: 5px;
    padding-top:4px;
    padding-bottom:4px;
    padding-left: 10px;
}
.pleasewait{
    position: relative;
    top:-180px;
}
.pleasewaittext{
    font-family: 'DentonXboldItalic';
}
.login_desc{
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 30px;
}
.spinner {
    animation: rotator 1.4s linear infinite;
    position: relative;
    top:-170px;
}

.spinnerLocal {
    animation: rotator 1.4s linear infinite;
    position: relative;
    right: 10px;
    top:2px;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(270deg);
    }
}

.path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes colors {
    0% {
        stroke: #4285F4;
    }

    25% {
        stroke: #DE3E35;
    }

    50% {
        stroke: #F7C223;
    }

    75% {
        stroke: #1B9A59;
    }

    100% {
        stroke: #4285F4;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 187;
    }

    50% {
        stroke-dashoffset: 46.75;
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: 187;
        transform: rotate(450deg);
    }
}
.newtostadiverse{
    font-size: 0.7rem;
}
.registerhere{
    color:var(--sub1);
    font-size: 0.7rem;
    transition: all 0.1s ease-in-out;
    padding:0.2rem;
    margin-top:0.6rem;
    margin-bottom:0.8rem;
    cursor: pointer;
}
.registerhere:hover{
    color:var(--sub3);
    font-size: 0.9rem;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
}
.showpassword{
    display: flex;
    margin-left: -22px;
    margin-top: 15px;
    font-size: 18px;
    padding:1px;
    width:0px;
    background: transparent;
    cursor:pointer;
    color:var(--sub2);
}
.passwordDiv{
    display: flex;
}
.loginErrors{
    font-family: 'DentonRegular';
    color: var(--text-color);
    font-size: 0.75rem;
    border-radius: 4px;
    margin:1px;
    inline-size:200px;
    background-color: var(--sub3);
    overflow-wrap: break-word;
}