.free-navbar {
  height: 70px;
  font-family: 'DentonLight';
  font-size: 0.8rem;
  overflow: hidden;
  margin-bottom: 10px;
}

.free-menus {
  display: flex;
  overflow: hidden;
  list-style: none;
  list-style: none;
  justify-content: space-evenly;
  font-size: inherit;
  margin: 0;
}

.free-menu-items {
  display: inline-block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  padding-left: 0px;
  padding-right: 0px;
  background-color: var(--bg);
}

button.manage-navbutton:hover {
  color: var(--sub3);
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
}

.manage-content {
  background-color: inherit;
  display: flex;
}

button.manage-navbutton {
  cursor: pointer;
  color: inherit;
  border: none;
  padding: 8px 26px 8px 26px;
  font: inherit;
  cursor: pointer;
  border-radius: 10px;
  border-bottom: 2px solid var(--sub3);
  border-top: 2px solid var(--bg);
  border-left: 2px solid var(--bg);
  border-right: 2px solid var(--bg);
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  transition: all 0.2s ease-in-out;
}

button.active {
  background-color: var(--bg);
  border: 2px solid var(--sub3);
  padding: 8px 26px 8px 26px;
}

/*PAGES CSS*/
.manageTeamBody {
  font-family: 'DentonRegular';
  font-size: 14px;
}

.dash-header {
  background-image: linear-gradient(60deg, var(--sub4), var(--bg-accent));
  background-clip: text;
  display: flex;
  text-align: center;
  justify-content: center;
  color: transparent;
  font-family: 'DentonXBold';
  font-size: 20px;
  text-transform: uppercase;
  white-space: nowrap;
}

.Sailboat-image {
  max-width: 300px;
  width: 50%;
  height: auto;
}

.image-credit {
  font-size: 12px;
  font-style: 'DentonLight';
}

.spinner2 {
  animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.path2 {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #4285F4;
  }

  25% {
    stroke: #DE3E35;
  }

  50% {
    stroke: #F7C223;
  }

  75% {
    stroke: #1B9A59;
  }

  100% {
    stroke: #4285F4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.spinnerDiv {
  justify-content: center;
  display: flex;
}

.teamBanner {
  background-color: var(--sub1);
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  min-width: 300px;
  border-color: #000000;
}

.teamBannerInfo {
  background-color: inherit;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  margin-inline: 10px;
  font-size: min(2.5vw, 12px);
}

.teamName {
  font-size: large;
  font-family: 'DentonXBold';
  background-color: inherit;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.teamInfo {
  background-color: inherit;
  display: flex;
  margin: 5px 10px 5px 10px;
}

.teamFormForm {
  display: block;
  width: 100%;
}

.teamFormDescription {
  display: inline-table;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px;
  width: auto;
}

.teamFormItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.teamFormButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.teamErrorText {
  color: var(--text-color);
  background-color: var(--sub4);
  padding: 2px 5px 2px 5px;
  border-radius: 5px;
  max-width: 245px;
}

.teamFormText {
  font-size: large;
  font-family: 'DentonXBold';
  background-color: inherit;
  justify-content: center;
  align-items: center;
}

.teamForm {
  display: flex;
  justify-content: center;
}

.teamCost {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.manageTeamBody {
  background-color: var(--bg);
  height: auto;
  margin: 0px 10px 0px 10px;
  border: 1px solid var(--var2);
  border-radius: 5px;
}

.entitiesBody {
  background-color: inherit;
  border-radius: inherit;
  display: grid;
  grid-template-columns: 33% 33% 34%;
  min-width: 300px;
}

.entitiesHeading {
  background-color: inherit;
  align-items: center;
  grid-column: 1 / span 3;
  text-align: center;
  font-family: 'DentonXBold';
  margin: 5px 0px 5px 0px;
}

.entitiesList {
  background-color: inherit;
  align-items: center;
  text-align: center;
  background-color: inherit;
  border-bottom: 1px solid var(--text-color);
  font-size: min(2.5vw, 12px);
  padding: 0px 0px 3px 0px;
}

.submitConfig {
  text-decoration: none;
  cursor: pointer;
  margin: 0px 5px 0px 10px;
  border: none;
  border-radius: 5px;
  padding: 2px 4px 2px 4px;
  background-color: var(--bg);
  border: 2px solid var(--sub1);
  font-size: min(2.5vw, 12px);
}

.submitConfig:hover {
  border: 2px solid var(--sub3);
  padding: 2px 4px 2px 4px;
  filter: brightness(110%);
}

.createEntitiesList {
  font-family: 'DentonLight';
}

.createEntityInstance {
  display: flex;
  justify-content: space-between;
  text-align: justify;
  border: var(--text-color) 2px solid;
  padding: 2px;
  margin: 2px 2px 2px 2px;
  border-radius: 10px;
  align-items: center;
  font-size: min(2.5vw, 15px);
}

.createEntityInstance:hover {
  filter: brightness(104%);
}

.ownedEntityInstance{
  border: var(--text-color) 2px solid;
  padding: 2px;
  margin: 2px 2px 2px 2px;
  border-radius: 10px;
  align-items: center;
  font-size: min(2.5vw, 15px);
  user-select: none;
}
.ownedEntityHeader{
  display: flex;
  justify-content: space-between;
  text-align: justify;
  font-size: min(2vw, 15px);
  align-items: center;
}
.ownedEntityInstance:hover{
  filter: brightness(104%);
  cursor: pointer;
}
.ownedEntityBody{
  font-size: min(2vw, 15px);
}

.popup-container {
  position: absolute;
  top: 120px;
  left: 50%;
  height: 80%;
  width: 100%;
  background: var(--bg);
  transform: translate(-50%, 0);
  z-index: 2;
}
.popup-body{
  background: var(--bg-accent);
  margin: 0% 9% 0% 9%;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
}
.popupHeader{
  background: inherit;
  display: flex;
  justify-content:space-between;
  border-radius: inherit;
}
.popupHeaderDiv{
  background: inherit;
  font-family: 'DentonXBold';
}
.popupFormContainer{
  display:flex;
  margin: 10px 0px 0px 0px;
  background: inherit;
}
.popupFormDiv{
  background: inherit;
  margin: 5px 0px 0px 0px;
}
.popupForm{
  background: inherit;
  display:inline-table;
  align-items: center;
  width:300px;
}
sup{
  background: inherit;
}
.closeButton{
  text-decoration: none;
  border: var(--text-color) solid 2px;
  border-radius: 5px;
  cursor: pointer;
  background: inherit;
  padding: 5px;
  font-family: 'DentonXboldItalic';
}
.closeButton:hover{
  filter:brightness(104%);
  color: var(--sub1);
  border-color: var(--sub3);
}
.errorNotice{
  background: var(--sub4);
  margin: 5px 0px 5px 0px;
  color: var(--text-color);
  padding: 3px;
  border-radius: 3px;
  word-wrap: normal;
  max-width: 300px;
}