:root {
  --bg: #eae7dc;
  --bg-accent: #d8c3a5;
  --text-color: #000000;
  --sub1:#e98074;
  --sub2:#8e8d8a;
  --sub3:#e85a4f;
  --sub4:red;
  --navsize:40px;
  --footsize:200px;
  --border-radius:6px;
  --border: 1px solid #4e4e50;
  --speed:500ms;
}

@font-face {
  font-family: DentonXbold;
  src: url('./fonts/DentonTextTest-ExtraBold.otf') format('opentype');
}
@font-face {
  font-family: DentonXboldItalic;
  src: url('./fonts/DentonTextTest-ExtraBoldItalic.otf') format('opentype');
}
@font-face {
  font-family: DentonRegular;
  src: url('./fonts/DentonTextTest-Regular.otf') format('opentype');
}
@font-face {
  font-family: DentonLight;
  src: url('./fonts/DentonTextTest-Light.otf') format('opentype');
}

* {
  margin:0;
  padding: 0;
  color: var(--text-color);
  background-color: var(--bg);
  font-family: 'DentonRegular';
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
a{
  text-decoration: none;
}
div.homepage-top{
  justify-content: center;
  /* margin-top: 5vh; */
}
div.intro{
  font-size: 4vw;
  font-family: 'DentonXboldItalic';
  font-weight:normal;
  font-style:normal;
}
div.heading{
  font-size: 8vw;
  font-weight:normal;
  font-style:normal;
  display: flex;
  justify-content: center;
  margin-top: 1vh;
}
span.stadi{
  font-family: 'DentonXbold';
  color: var(--sub3);
  font-size: inherit;
}
span.verse{
  font-family: 'DentonXboldItalic';
  font-size: inherit;
  color: var(--text-color);
}
.navbar {
  /* height: var(--navsize); */
  background-color: var(--bg);
  padding:0.1rem;
  text-align: center;
  display: flex;
  /* justify-content: space-around; */
  font-family: 'DentonRegular';
  position:relative;
  z-index: 10;
}
.menus {
  display: flex;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  border-radius: 0.5rem;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  font-family: 'DentonRegular';
  border-radius: 0.5rem;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}
.menu-items:hover{
  filter: brightness(103%);
  color:var(--sub3);
}
.menu-items a:hover,
.menu-items button:hover {
  filter: brightness(103%);
  background-color: var(--sub1);
  transition:all 0.3s ease-in-out;
  color:var(--text-color);
}
.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 6.25rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: var(--bg);
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}
.footer {
  height: var(--footsize);
  background-color: var(--sub1);
  padding:0.1rem;
  text-align: center;
  justify-content: center;
  font-family: 'DentonRegular';
}
.testButton{
  /*Reset's every elements apperance*/
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  color: #26589F;
  font-family: 'PT Sans Narrow',sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.42rem;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
}
